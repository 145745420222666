.expired-notice {
  text-align: right;
  padding: 0.0rem;
  border: 0px solid transparent;
  border-radius: 0.0rem;
  margin: 0.0rem;
}

.expired-notice > span {
  font-size: 1.0rem;
  font-weight: bold;
  color: red;
}

.expired-notice > p {
  font-size: 1.0rem;
}

.show-counter {
  padding: 0.0rem;
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  font-weight: 700;
  font-size: 1.0rem;
  line-height: 0.00rem;
  padding: 0.0rem;
  border: 0px solid transparent;
  border-radius: 0.00rem;
  text-decoration: none;
  *color: #000;
  color:red;
}

.show-counter .countdown {
  line-height: 1.00rem;
  padding: 0 0.70rem 0 0.70rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: #ff0000;
}

.show-counter .countdown > p {
  margin: 0;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}
